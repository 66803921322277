/**
 * @file Route: home
 *
 * This file is compiled as a standalone entrypoint and it is included by
 * default in its blade template.
 * This is a "standard" route, it does not have a structure to follow, you are
 * sure the DOM is always ready and the JS will only be executed in this route.
 */

import "./index.scss";
import "layouts/main";
import { $all, forEach } from "@olmokit/dom";
import {
    glide,
    Anchors,
    Autoplay,
    AutoHeight,
    Controls,
    LazyLoad,
    Breakpoints,
    // Fade,
    Swipe,
} from "@olmokit/core/glide";
import "@olmokit/core/glide/arrows";
import zacc from "@olmokit/core/zacc";

const glideOptions = {
    type: "slider",
    perView: 1,
    focusAt: 0,
    startAt: 0,
    //   autoplay: 6000,
    autoHeight: true,
    hoverpause: false,
    gap: 0,
    loop: false,
    animationDuration: 600,
    animationTimingFunc: "cubic-bezier(.43,.16,.44,1.01)",
};

const element = document.getElementsByClassName("home:contentSlider__glide");
if (element.length > 0) {
    const slider = glide(element[0], glideOptions);
    slider.mount({
        Swipe,
        LazyLoad,
        Anchors,
        Controls,
        Autoplay,
        // Fade,
        AutoHeight,
    });
}

const glideOptions3 = {
    type: "slider",
    perView: 1,
    focusAt: 0,
    startAt: 0,
    autoplay: 3000,
    autoHeight: true,
    hoverpause: true,
    gap: 0,
    loop: true,
    animationDuration: 600,
    animationTimingFunc: "cubic-bezier(.43,.16,.44,1.01)",
};

const element3 = document.getElementsByClassName("home:newsSlider__glide");
if (element3.length > 0) {
    const slider = glide(element3[0], glideOptions3);
    slider.mount({
        Swipe,
        LazyLoad,
        Anchors,
        Controls,
        Autoplay,
        // Fade,
        AutoHeight,
    });
}

const glideOptions2 = {
    type: "slider",
    focusAt: 0,
    startAt: 0,
    animationDuration: 600,
    animationTimingFunc: "cubic-bezier(.43,.16,.44,1.01)",
    perView: 2,
    peek: { before: 0, after: 0 },
    gap: 0,
    bound: true,
    loop: false,
    breakpoints: {
        1440: {
            perView: 2,
            peek: { before: 0, after: 0 },
            gap: 0,
        },
        1200: {
            perView: 2,
            peek: { before: 0, after: 0 },
            gap: 0,
        },
        992: {
            perView: 1,
            peek: { before: 0, after: 0 },
            gap: 0,
        },
        768: {
            perView: 1,
            peek: { before: 0, after: 0 },
            gap: 0,
        },
        576: {
            perView: 1,
            peek: { before: 0, after: 0 },
            gap: 0,
        },
    },
};

const element2 = document.getElementsByClassName("home:slider");
if (element2.length > 0) {
    const slider = glide(element2[0], glideOptions2);
    slider.mount({
        Swipe,
        LazyLoad,
        Autoplay,
        Breakpoints,
        Anchors,
        Controls,
    });
}

forEach($all(".zacc"), ($list) => {
    zacc($list, { duration: 300 });
});

console.log("Route home/index.js mounted.");
